
/**
 * LANDING PAGE DASHBOARD CONTROLS
 */

 .landingStats {
  margin-top: 0;
}
.landingStats .totals {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
}

.lists {
  max-height: 200px;
  overflow: auto;
  border-radius: 0.5rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.07);
}
.lists ul {
  display: inline-block;
  width: 100%;
  margin-top: 1rem;
  padding: 0 20px;
}
.lists li {
  display: inline-block;
  width: 100%;
  margin: 5px 0;
}
.lists li span {
  float: right;
}

.svgContainer {
  background: #fff;
  border-radius: 50%;
  border: 15px solid #000;
  height: 152px;
  width: 152px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .svgContainer {
    margin-top: 60px;
  }
}

.percentChange {
  position: relative;
  top: -10px;
  font-weight: bold;
}

.red {
  border: 15px solid red;
}
.red svg {
  fill: red;
}
.red span {
  color: red;
}

.green {
  border: 15px solid green;
}
.green svg {
  fill: green;
}
.green span {
  color: green;
}

/**
 * REGION PAGE DASHBOARD CONTROLS
 */
.gauge {
  text-align: center;
}

.dashlabel {
  padding-left: 10px;
}

#demo1, #demo {
  margin-top: -15px;
}

#locationtable, #location {
  display: none;
}

button#dropdownMenu {
  background: #F5F5F5 !important;
}

button#dropdownMenu:hover{
  background:  #ab2035 !important;
  color:white !important;
}

.slider-handle.min-slider-handle.round {
  background: #ab2035;
}

button.btn:hover{
  background:  #ab2035 !important;
  color:white !important;

}