/**
 * MODALS
 */

 @media (min-width: 992px) {
  .modal-lg {
    max-width: 900px;
  }
}

/**
 * ABOUT
 */
#about img {
  max-width: 250px;
}

/**
 * ACCESSIBILITY
 */
#accessibility ul {
  list-style-type: disc;
}

.sub-li {
  padding-left: 40px;
  list-style: none;
}

/**
 * COMMENTS
 */
.accsform textarea,
#commentForm textarea {
  width: 100%;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  color: black;
}