/*
 * Containers 
 */

#map {
  position: relative;
}

#dropdownDiv {
  width: 360px;
}