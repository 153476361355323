/****************
BASE
****************/

* {
  box-sizing: border-box;
  font-family: "Rubik", apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

*::before {
  font-family: "FontAwesome";
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  html {
    font-size: 14px;
  }
}

#wrapper {
  width: 100%;
}

ul {
  list-style: none;
}

.fa {
  -ms-high-contrast-adjust: none;
}

.modal-header .close {
  margin: -0.65rem -1rem -1rem auto;
}

.datepicker-decades thead .datepicker-switch {
  cursor: default;
  pointer-events: none;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  left: 103px;
  top: 71px;
}

