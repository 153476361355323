/***************
MEDIA QUERIES
***************/

$mobile-width: 318px;
$mobile2-width: 400px;
$tablet-width: 768px;
$desktop-width: 992px;
$largedesktop-width: 1200px;
$xlargedesktop-width: 1900px;

@mixin mobile {
  @media (min-width: #{$mobile-width}) {
    @content;
  }
}

@mixin mobile2 {
  @media (min-width: #{$mobile2-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin large {
  @media (min-width: #{$largedesktop-width}) {
    @content;
  }
}

@mixin xllarge {
  @media (min-width: #{$xlargedesktop-width}) {
    @content;
  }
}
