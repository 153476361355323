/**
 * FOOTER
 */

footer {
  background: #444444;
}
footer {
  padding: 5px 0;
}
footer p {
  margin: 0;
}