/**
 * NAVBAR
 */

 #customerlogo {
  width: 200px;
  height: 43px;
  padding: 0;
}
@media (min-width: 768px) {
  #customerlogo {
    width: 280px;
    height: 60px;
  }
}

.navbar-brand {
  margin-right: 0;
}

.navbar-nav .nav-item .nav-link {
  color: white;
  font-weight: 700;
  background: none;
  border: none;
}