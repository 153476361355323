/**
 * MAP
 */
 .AIOSearch {
  padding-top: 0.5rem;
}

#landingmap {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 400px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.07);
  border-radius: 0.25rem;
  overflow: hidden;
  background: #dfaaaa;
}

/**
 * MAP OPTIONS
 */
.mapOptions {
  display: none;
  width: auto;
  position: absolute;
  left: 0px;
  top: 40px;
  z-index: 3;
  background: white;
  padding: 10px 5px;
  margin: 0 15px;
  border-radius: 0.25rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.07);
}
@media (min-width: 768px) {
  .mapOptions {
    padding: 10px 15px;
  }
}
.mapOptions div {
  margin: 10px 0;
}
.mapOptions label {
  cursor: pointer;
}

.searchrow {
  border-bottom: dotted 1px #606da6;
  padding-bottom: 4px;
}

.search {
  width: 100%;
}
@media (min-width: 1200px) {
  .search {
    width: 100%;
  }
}
.search button {
  border-bottom-right-radius: 0;
}
.search input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
@media (min-width: 1200px) {
  .search input {
    height: 40px;
  }
}
.search .dropdown-menu {
  width: 100% !important;
  overflow-x: hidden;
}
.search .dropdown-menu .dropdown-item {
  padding: 0.25rem 1rem;
}

.RegionError {
  position: absolute;
  right: 15px;
  top: 37px;
  width: 240px;
  z-index: 2;
  background: #fff;
  border-radius: 0 0 0.25rem 0.25rem;
}
@media (min-width: 1200px) {
  .RegionError {
    width: 300px;
    top: 40px;
  }
}
.RegionError p {
  color: red;
  margin: 5px 0 5px 5px;
}

/**
 * LEGEND
 */
.legend {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  text-align: center;
  padding: 0;
}
@media (min-width: 1200px) {
  .legend {
    top: unset;
    bottom: 0;
    left: 0;
  }
}

.legend span {
  font-size: 0.9rem;
  display: block;
  width: 65px;
  padding: 2px;
  color: #000;
}
@media (min-width: 1200px) {
  .legend span {
    float: left;
    width: 9.09%;
  }
}

/**
 * POPUP
 */
#info {
  position: relative;
  height: 1px;
  width: 1px;
  z-index: 100;
}