/**
 * BUTTONS
 */

 .modal .btn {
  width: 100%;
}
.modal .btn {
  font-weight: 700;
}
.modal .btn:hover, .modal .btn:focus {
  color: #212529;
  border: 1px solid #212529;
  background: #444444;
  transition: all 0.7s;
}

.landgear {
  position: absolute;
  z-index: 3;
  width: 40px;
  height: 40px;
  padding: 5px;
}

.mapsize {
  position: absolute;
  top: 5px !important;
  right: 5px;
  width: 50px;
  height: 40px;
  z-index: 980;
  margin: 0;
}

#toolbar {
  top: 5px !important;
  left: 5px !important;
}

.locposbutton {
  top: 5px !important;
}