/**
 * APP STYLE
 */
 
 @font-face {
  font-family: "DS-Digital";
  src: url("/fonts/DS-Digital.ttf.woff") format("woff"), url("/fonts/DS-Digital.ttf.svg#DS-Digital") format("svg"), url("/fonts/DS-Digital.ttf.eot"), url("/fonts/DS-Digital.ttf.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}
.slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, white 0, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff89cdef",endColorstr="#ff81bfde",GradientType=0);
}

.slider-tick.in-selection {
  background-image: linear-gradient(to bottom, white, 0, white 100%) !important;
}

.container-gauge {
  border: dotted 1px #606da6;
  border-radius: 5px;
  margin-bottom: 0.3em !important;
}

.casualtiesRate, .casualtiesRate1, .nationalNumber, .nationalNumber1 {
  font-family: "DS-Digital" !important;
  font-size: 40px;
  margin: auto;
}



.checkboxes {
  margin: auto;
}

#nationalNumber, #nationalNumber1 {
  border: 4px solid grey;
  border-radius: 5px;
}

.casualtiesRate, .casualtiesRate1 {
  border: 4px solid grey;
  border-radius: 5px;
}

.filter-by {
  padding-top: 1em !important;
  padding-bottom: 0.3em !important;
}

.gauge_title {
  text-align: center;
}

.gauge_text {
  text-align: center;
}

.zero, .min {
  font-size: 18px;
  visibility: hidden;
}

.max {
  color: white;
  font-size: 18px;
  background: red;
}

.sort {
  font-size: 1rem;
  display: block;
  text-align: center;
}

.cgauge {
  padding-right: 1.5rem;
}

.title-gauge {
  font-size: 20px;
  margin: auto;
  justify-content: center;
  padding: 0 6em 1em 0;
}

.gauge_col {
  text-align: center;
}


h2 {
  color: #ab2035;
}

h4 {
  padding-top: 0.5rem;
  font-size: 1.5rem;
  color: #ab2035;
}

.form-group {
  height: 100%;
  width: 33%;
  float: right;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1em 0em 0em 0;
}

.aTargetModal {
  color: #dc143c !important;
  cursor: pointer;
}
.aTargetModal:hover {
  color: #dc143c !important;
  text-decoration: underline !important;
}

.navbar,
.btn,
.modal-header {
  background: #444444;
  border: 1px solid #606da6;
  color: #606da6;
}

.navbar-toggler {
  border: 1px solid #4F72A7;
}

.navbar {
  border: none;
  border-bottom: 2px solid #4F72A7;
}

.navbar-toggler .fas {
  vertical-align: middle;
  margin-top: 6px;
}

.modal-header {
  border: none;
  border-bottom: 1px solid #4F72A7;
}

.navbar-nav .nav-item .nav-link:hover {
  color: #212529;
}

#customerlogo {
  width: 300px;
  height: 70px;
  padding: 0;
}

.ol-overviewmap-map .ol-viewport,
.ol-viewport {
  background: #d7eafc;
}

.ol-overviewmap-box {
  border: 2px solid red;
}

.ol-rotate-reset,
.ol-scale-line {
  background: #F5F5F5;
  color: #ab2035 !important;
}

.ol-scale-line-inner {
  border: 1px solid #597cb0;
  border-top: 0;
  color: #597cb0 !important;
}

#accsLimit {
  position: absolute;
  z-index: 10;
  margin: 0;
  bottom: 50px;
  left: 10px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 0.5em;
  font-size: 16px;
  font-weight: bold;
  color: red;
  padding: 4px;
}

@media (min-width: 768px) {
  #dropdownMenu,
.btn,
.custbtn,
.groupbtn,
.modal-header,
.navbar,
.tlruler,
.tlruler:hover,
footer {
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#e3efff", endColorstr="#ffffff", GradientType=0 );
  }

  #collDisplay,
#feature_list label,
#find,
#search,
.helpamdisclaimer {
    color: #444444;
  }

  #dropdownMenu,
.navbar {
    background: #444444;
    
  }

  .navbar-nav .nav-item .nav-link {
    color: white;
    font-weight: 700;
  }

  .navbar-nav .nav-item .nav-link:focus,
.navbar-nav .nav-item .nav-link:hover {
    color: #9d2b35;
    font-weight: 700;
  }

  .navbar-toggler {
    border-color: #597cb0;
  }

  .fa-bars {
    color: #597cb0;
  }

  .fa-bars:hover {
    color: #9d2b35;
  }

  .amdisclaimer,
.close span,
.modal,
.modal-header {
    color: white;
  }

  #dropdownMenu {
    border: 1px solid #597cb0 !important;
  }

  .modal-header {
    background: #444444;
    border-bottom: 1px solid #ab2035;
  }

  .btn,
.custbtn,
.groupbtn,
.tlruler,
.tlruler:hover {
  background: #F5F5F5;
  color: #ab2035;
  border: 1px solid #ab2035;
  }

  .btn:focus,
.btn:hover,
.custbtn:focus,
.custbtn:hover,
.custbtnsel,
.groupbtn:focus,
.groupbtn:hover,
.tlruler:hover {
    background: #ab2035;
    color: white;
    border: 1px solid #9d2b35;
  }

  #popup-closer {
    color: #597cb0;
  }

  #popup-closer:hover {
    color: #9d2b35;
  }

  .locposbutton .posiconSvg,
.tlruler .rulerSvg {
    fill: #597cb0;
    stroke-width: 10px;
    stroke: #597cb0;
    margin-top: 2px;
  }

  .locposbutton:hover .posiconSvg,
.tlruler:hover .rulerSvg {
    fill: #9d2b35;
    stroke: #9d2b35;
  }

  .searchcont {
    color: #597cb0;
  }

  #feature_list .fa-close:before,
#feature_list .fa-remove:before,
#feature_list .fa-times:before,
#feature_list .fa:before {
    background: #e3efff;
    color: #597cb0;
    border: 1px solid #597cb0;
  }

  #feature_list .group_btn:hover em::before,
#feature_list .group_btn:hover::before,
#feature_list li:hover,
#feature_list li:hover em::before,
#feature_list li:hover label {
    color: #9d2b35;
  }

  #feature_list li:hover em::before {
    background: #fff;
  }

  .bar span {
    border: 1px solid #e3efff;
    border-bottom-color: #97c3ff;
    background-color: #fff;
    background-image: linear-gradient(-45deg, #e3efff 25%, transparent 25%, transparent 50%, #e3efff 50%, #e3efff 75%, transparent 75%, transparent);
  }

  .pdq_default {
    background: #0b3c5d;
    color: #fff;
  }

  .pdq_on {
    background: #328cc1;
    color: #d9b310;
  }

  footer {
    background: #444444;
    color: white;
  }

}
#copytext {
  bottom: 22px;
}

#map_extras {
  width: 250px;
  bottom: 25px;
  height: 40px;
}

#fromdate, #todate {
  padding: 0px;
}

#feature_list {
  padding-bottom: 5px;
}

.legImg {
  width: 30px;
  height: 20px;
}

.date_info {
  font-size: smaller;
}

#accidents_list, #acc_title, .datedisplay {
  padding-left: 20px !important;
}

.tooltip_landing {
  position: relative;
  left: 0px;
  top: 0px;
}

.tooltip_region {
  position: relative;
  padding-right: 5px;
  top: -2px;
}

.totals {
  display: inline-block;
}

.region_titles {
  display: inline-block;
  margin: auto;
}

.tooltip_text {
  color: white;
}

.title_group {
  margin: auto;
}

.regionName {
  display: inline-block;
  width: 100%;
  margin-top: 45px;
  text-align: center;
}

@media screen and (min-width: 490px) {
  .regionName {
    margin-top: 0px;
    text-align: left;
  }
}
input[type=checkbox] {
  display: inline-block;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider-switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider-switch:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider-switch {
  background-color: #ab2035;
}

input:focus + .slider-switch {
  box-shadow: 0 0 1px #597cb0;
}

input:checked + .slider-switch:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider-switch.round {
  border-radius: 34px;
}

.slider-switch.round:before {
  border-radius: 50%;
}

.switchlabel {
  position: relative;
  top: 5px;
}

.search_text {
  padding-right: 0px;
}

.search-box {
  padding-left: 0px;
}

#help ul {
  list-style: circle;
  padding-left: 30px;
}

.RegionError {
  display: none;
}

.RegionError p, .missingData, .missingData1 {
  color: red;
  font-weight: bold;
}

.fixed-needle {
  position: absolute;
}

a {
  color: #dc143c;
  text-decoration: none;
  background-color: transparent;
}