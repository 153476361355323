.loading {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	height: 0;
	z-index: 9999;
  }
  
  .bar {
	display: table;
	height: 20px;
	width: 200px;
	padding: 10px;
	margin: 200px auto 0;
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 20px;
	-ms-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.03), inset 0 1px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 1px 0 rgba(255, 255, 255, 0.03), inset 0 1px 0 rgba(0, 0, 0, 0.1);
  }
  
  /*
  This is the actual bar with stripes
  */
  .bar span {
	display: inline-block;
	width: 100%;
	height: 25px;
	position: relative;
	top: 3px;
	border: 1px solid #0b3c5d;
	border-bottom-color: #051e2e;
	background-color: #fff;
	border-radius: 20px;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
	background-image: linear-gradient(-45deg, #0b3c5d 25%, transparent 25%, transparent 50%, #0b3c5d 50%, #0b3c5d 75%, transparent 75%, transparent);
	-ms-background-size: 50px 50px;
	background-size: 50px 50px;
	-webkit-animation: move 2s linear infinite;
	animation: move 2s linear infinite;
	border-radius: 20px;
	overflow: hidden;
	-ms-box-shadow: inset 0 10px 0 rgba(255, 255, 255, 0.2);
	box-shadow: inset 0 10px 0 rgba(255, 255, 255, 0.2);
  }
  
  /*
  Animate the stripes
  */
  @-webkit-keyframes move {
	0% {
	  background-position: 0 0;
	}
	100% {
	  background-position: 50px 50px;
	}
  }
  @keyframes move {
	0% {
	  background-position: 0 0;
	}
	100% {
	  background-position: 50px 50px;
	}
  }